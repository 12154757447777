import {
    Card, CardContent, Grid, TextField, Typography, MenuItem, FormControl, Select, Box, Button, Checkbox, ListItemText, Link
  } from '@mui/material';
  import { DatePicker } from "@mui/x-date-pickers/DatePicker";
  import React, { useEffect, useState } from 'react';
  import DocumentIcon from '@mui/icons-material/Description';
  import { LocalizationProvider } from '@mui/x-date-pickers';
  import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { useFetchResumeFileQuery } from 'services/caregiverApi';
import { useSnackbar } from 'components/SnackbarProvider';
import { useLoading } from 'components/LoadingProvider';
  
  const languages = [
    'English', 'Hindi', 'Tamil', 'Telugu', 'Malayalam', 'Kannada', 'Bengali', 'Marathi', 'Gujarati'
  ];

  function getRandomFourDigitNumber() {
    return Math.floor(1000 + Math.random() * 9000);
  }
  const PersonalInfoCard = ({ caregiverDetails, handleChange,handleChangeNameValue, isEditing }) => {
    const [open, setOpen] = useState(false);
    const [selectedLanguages, setSelectedLanguages] = useState("");
    const [downloadResume, setDownloadResume] = useState(false); // State to trigger resume download
    const showSnackbar = useSnackbar();
    const { startLoading, stopLoading } = useLoading();

  // useFetchResumeFileQuery to get resume data based on downloadResume state
  const { data: resumeData, error: resumeError, isFetching: isFetchingResume } = useFetchResumeFileQuery(
    downloadResume ? { fileUrl: caregiverDetails?.resumeFile } : null,
    { skip: !downloadResume }
  );
    useEffect(()=>{
      if(caregiverDetails?.languages){
        setSelectedLanguages(caregiverDetails?.languages?.join(", "))
      }
    },[isEditing])

    const handleToggle = () => {
      setOpen(!open);
    };
    const handleLanguageChange = (event) => {
      const inputValue = event.target.value;
      const languageArray = inputValue
        .split(',')
        .map(language => language.trim())
        .filter(language => language); // Remove empty strings
      setSelectedLanguages(inputValue);
      handleChange({ target: { name: 'languages', value: languageArray } });
    };
    // const handleOkClick = () => {
    //   handleChange({ target: { name: 'languages', value: selectedLanguages } });
    //   setOpen(false);
    // };
  
  // Trigger resume download
  const handleDownloadResume = () => {
    setDownloadResume(true); // Set state to trigger the download
  };

  useEffect(() => {
    if (isFetchingResume) {
      startLoading();
    } else {
      stopLoading();
    }
  }, [ isFetchingResume]);

  // Error handling
  useEffect(() => {
    if (resumeError) {
      showSnackbar(resumeError?.status, "error");
    }
  }, [resumeError]);

  useEffect(() => {
    if (resumeData && downloadResume) {
      // Create a blob URL and specify the correct MIME type if needed
      const url = window.URL.createObjectURL(new Blob([resumeData], { type: resumeData.type || 'application/octet-stream' }));
  
      // Create a temporary link element
      const link = document.createElement('a');
      link.href = url;
  
      // Set the file name for download using resumeFile or a default name
      const fileName = 'resume_' + getRandomFourDigitNumber();
      link.setAttribute('download', fileName);
  
      // Append the link to the DOM
      document.body.appendChild(link);
  
      // Trigger the download
      link.click();
  
      // Remove the link from the DOM
      link.parentNode.removeChild(link);
  
      // Revoke the object URL to free up memory
      window.URL.revokeObjectURL(url);
  
      // Reset download state
      setDownloadResume(false);
  
    } else if (resumeError) {
      // Show an error message if the download fails
      showSnackbar("Failed to download the resume", "error");
      setDownloadResume(false);
    }
  }, [resumeData, resumeError, caregiverDetails, downloadResume]);
  
  function getResumeName() {
    if (caregiverDetails?.resumeFile?.name){
      return caregiverDetails?.resumeFile?.name
    }
    return caregiverDetails?.resumeFile ? caregiverDetails?.resumeFile.replace(/\/$/, '').split('/').pop() : "No Resume";
  }
    return (
      <Card>
        <CardContent>
          <Typography variant="h6" component="div">Personal Info</Typography>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Grid container spacing={2}>
              {/* Other fields */}
              <Grid item xs={12} sm={4}>
                {isEditing ? (
                  <TextField
                    required
                    fullWidth
                    label="First Name (required)"
                    name="firstName"
                    value={caregiverDetails?.firstName || ''}
                    onChange={handleChange}
                  />
                ) : (
                  <>
                    <Typography variant="subtitle2">First Name</Typography>
                    <Typography variant="body1">
                      {caregiverDetails?.firstName || 'Yet to add'}
                    </Typography>
                  </>
                )}
              </Grid>
              <Grid item xs={12} sm={4}>
                {isEditing ? (
                  <TextField
                    fullWidth
                    required
                    label="Last Name (required)"
                    name="lastName"
                    value={caregiverDetails?.lastName || ''}
                    onChange={handleChange}
                  />
                ) : (
                  <>
                    <Typography variant="subtitle2">Last Name</Typography>
                    <Typography variant="body1">
                      {caregiverDetails?.lastName || 'Yet to add'}
                    </Typography>
                  </>
                )}
              </Grid>
              <Grid item xs={12} sm={4}>
                {isEditing ? (
                  <TextField
                    fullWidth
                    label="Contact Number"
                    name="contactNumber"
                    value={caregiverDetails?.contactNumber || ''}
                    onChange={handleChange}
                  />
                ) : (
                  <>
                    <Typography variant="subtitle2">Contact Number</Typography>
                    <Typography variant="body1">
                      {caregiverDetails?.contactNumber || 'Yet to add'}
                    </Typography>
                  </>
                )}
              </Grid>
              <Grid item xs={12} sm={4}>
                {isEditing ? (
                  <TextField
                    fullWidth
                    label="Email"
                    name="email"
                    value={caregiverDetails?.email || ''}
                    onChange={handleChange}
                  />
                ) : (
                  <>
                    <Typography variant="subtitle2">Email</Typography>
                    <Typography variant="body1">
                      {caregiverDetails?.email || 'Yet to add'}
                    </Typography>
                  </>
                )}
              </Grid>
              <Grid item xs={12} sm={4}>
                {isEditing ? (
                  <TextField
                    fullWidth
                    label="Preferred Location"
                    name="preferredLocation"
                    value={caregiverDetails?.preferredLocation || ''}
                    onChange={handleChange}
                  />
                ) : (
                  <>
                    <Typography variant="subtitle2">Preferred Location</Typography>
                    <Typography variant="body1">
                      {caregiverDetails?.preferredLocation || 'Yet to add'}
                    </Typography>
                  </>
                )}
              </Grid>
              <Grid item xs={12} sm={4}>
                {isEditing ? (
                  <DatePicker
                    sx={{width: "100%"}}
                    label="Date of Availability"
                    value={
                      caregiverDetails?.dateOfAvailability
                        ? dayjs(caregiverDetails.dateOfAvailability, "YYYY-MM-DD")
                        : null
                    }
                    onChange={(newValue) => handleChange({ target: { name: 'dateOfAvailability', value: newValue.format("YYYY-MM-DD") } })}
                    slotProps={{ textField: { fullWidth: true } }}
                  />
                ) : (
                  <>
                    <Typography variant="subtitle2">Date of Availability</Typography>
                    <Typography variant="body1">
                      {caregiverDetails?.dateOfAvailability || 'Yet to add'}
                    </Typography>
                  </>
                )}
              </Grid>
              <Grid item xs={12} sm={4}>
                {isEditing ? (
                  <DatePicker
                    sx={{width: "100%"}}
                    label="Date of Birth"
                    value={
                      caregiverDetails?.dateOfBirth
                        ? dayjs(caregiverDetails.dateOfBirth, "YYYY-MM-DD")
                        : null
                    }
                    onChange={(newValue) => handleChange({ target: { name: 'dateOfBirth', value: newValue.format("YYYY-MM-DD") } })}
                    slotProps={{ textField: { fullWidth: true } }}
                  />
                ) : (
                  <>
                    <Typography variant="subtitle2">Date of Birth</Typography>
                    <Typography variant="body1">
                      {caregiverDetails?.dateOfBirth || 'Yet to add'}
                    </Typography>
                  </>
                )}
              </Grid>
              <Grid item xs={12} sm={4}>
                {isEditing ? (
                  <TextField
                    fullWidth
                    select
                    label="Gender"
                    name="gender"
                    value={caregiverDetails?.gender || ''}
                    onChange={handleChange}
                  >
                    <MenuItem value="male">Male</MenuItem>
                    <MenuItem value="female">Female</MenuItem>
                    <MenuItem value="other">Other</MenuItem>
                  </TextField>
                ) : (
                  <>
                    <Typography variant="subtitle2">Gender</Typography>
                    <Typography variant="body1">
                      {caregiverDetails?.gender || 'Yet to add'}
                    </Typography>
                  </>
                )}
              </Grid>
              <Grid item xs={12} sm={4}>
                {isEditing ? (
                  <TextField
                      fullWidth
                      select
                      label="Shift Preference"
                      name="shift_preference"
                      value={caregiverDetails?.shift_preference || ''}
                      onChange={handleChange}
                    >
                      <MenuItem value="morning">Morning</MenuItem>
                      <MenuItem value="night">Night</MenuItem>
                      <MenuItem value="live_in">Live-In</MenuItem>
                      <MenuItem value="both">Both</MenuItem>
                  </TextField>
                ) : (
                  <>
                    <Typography variant="subtitle2">Shift Preference</Typography>
                    <Typography variant="body1">
                      {caregiverDetails?.shift_preference || 'Yet to add'}
                    </Typography>
                  </>
                )}
              </Grid>
              <Grid item xs={12} sm={4}>
                {isEditing ? (
                  <TextField
                    fullWidth
                    select
                    label="Marital Status"
                    name="marital_status"
                    value={caregiverDetails?.marital_status || ''}
                    onChange={handleChange}
                  >
                    <MenuItem value="single">Single</MenuItem>
                    <MenuItem value="married">Married</MenuItem>
                    <MenuItem value="divorced">Divorced</MenuItem>
                    <MenuItem value="widowed">Widowed</MenuItem>
                  </TextField>
                ) : (
                  <>
                    <Typography variant="subtitle2">Marital Status</Typography>
                    <Typography variant="body1">
                      {caregiverDetails?.marital_status || 'Yet to add'}
                    </Typography>
                  </>
                )}
              </Grid>
              <Grid item xs={12} sm={4}>
                {isEditing ? (
                  <TextField
                    fullWidth
                    select
                    label="Religion"
                    name="religion"
                    value={caregiverDetails?.religion || ''}
                    onChange={handleChange}
                  >
                    <MenuItem value="hinduism">Hinduism</MenuItem>
                    <MenuItem value="islam">Islam</MenuItem>
                    <MenuItem value="christianity">Christianity</MenuItem>
                    <MenuItem value="sikhism">Sikhism</MenuItem>
                    <MenuItem value="buddhism">Buddhism</MenuItem>
                    <MenuItem value="other">Other</MenuItem>
                  </TextField>
                ) : (
                  <>
                    <Typography variant="subtitle2">Religion</Typography>
                    <Typography variant="body1">
                      {caregiverDetails?.religion || 'Yet to add'}
                    </Typography>
                  </>
                )}
              </Grid>
              <Grid item xs={12} sm={4}>
                {isEditing ? (
                  <>
                  <TextField
                  sx={{ width: '100%' }}
                  value={selectedLanguages}
                  onChange={handleLanguageChange}
                  placeholder="Enter languages separated by commas"
                />
                  </>
                ) : (
                  <>
                    <Typography variant="subtitle2">Spoken Languages</Typography>
                    <Typography variant="body1">
                      {caregiverDetails?.languages ? caregiverDetails?.languages.join(', ') : 'Yet to add'}
                    </Typography>
                  </>
                )}
              </Grid>
              <Grid item xs={12} sm={4}>
                {isEditing ? (
                  <TextField
                    fullWidth
                    label="Permanent Address Line 1"
                    name="permanent_address_line1"
                    value={caregiverDetails?.permanent_address_line1 || ''}
                    onChange={handleChange}
                  />
                ) : (
                  <>
                    <Typography variant="subtitle2">Permanent Address Line 1</Typography>
                    <Typography variant="body1">
                      {caregiverDetails?.permanent_address_line1 || 'Yet to add'}
                    </Typography>
                  </>
                )}
              </Grid>
              <Grid item xs={12} sm={4}>
                {isEditing ? (
                  <TextField
                    fullWidth
                    label="Permanent Address Line 2"
                    name="permanent_address_line2"
                    value={caregiverDetails?.permanent_address_line2 || ''}
                    onChange={handleChange}
                  />
                ) : (
                  <>
                    <Typography variant="subtitle2">Permanent Address Line 2</Typography>
                    <Typography variant="body1">
                      {caregiverDetails?.permanent_address_line2 || 'Yet to add'}
                    </Typography>
                  </>
                )}
              </Grid>
              <Grid item xs={12}>
                {isEditing ? (
                  <>
                    <Button
                      variant="outlined"
                      component="label"
                    >
                      Change Resume
                      <input
                        type="file"
                        name='resumeFile'
                        hidden
                        accept=".pdf,.docx"
                        onChange={(e) => handleChangeNameValue(e.target.name, e.target.files[0])}
                        />
                    </Button>
                  {caregiverDetails?.resumeFile && <Typography variant="body2">{"Resume : " + getResumeName()}</Typography>}
                    </>
                ) : (
                  <>
                  {/* Add a button to download the resume file */}
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleDownloadResume}
                    disabled={!caregiverDetails?.resumeFile}
                  >
                    Download Resume
                  </Button>
                  {caregiverDetails?.resumeFile && <Typography sx={{pt:1}} variant="body2">{"Resume : " + getResumeName()}</Typography>}
                  </>
                )}
              </Grid>
            </Grid>
          </LocalizationProvider>
        </CardContent>
      </Card>
    );
  };
  
  export default PersonalInfoCard;
  