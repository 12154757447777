import { Breadcrumbs } from '@mui/material';
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import HomeIcon from '@mui/icons-material/Home';
import { emphasize, styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import { useDispatch } from 'react-redux';
import { menuitems } from 'CommonData/Common';
import { setSelectedMenuItem } from 'slices/globaslice';

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
  const backgroundColor =
    theme.palette.mode === 'light'
      ? theme.palette.grey[100]
      : theme.palette.grey[800];
  return {
    backgroundColor,
    cursor: 'pointer',
    height: theme.spacing(3),
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
    '&:hover, &:focus': {
      backgroundColor: emphasize(backgroundColor, 0.06),
    },
    '&:active': {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(backgroundColor, 0.12),
    },
  };
});

const AppBreadcrumbs = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  
  const pathnames = location.pathname.split('/').filter((path) => path !== '');
  useEffect(() => {
    if (pathnames[0]) {
      const matchedItem = menuitems.find(
        (item) => item.name.toLowerCase() === pathnames[0].toLowerCase()
      );
      if (matchedItem) {
        dispatch(setSelectedMenuItem(matchedItem.id));
      }
    }
  }, [pathnames]);

  const handleClick = (to) => {
    navigate(to);
  };

  return (
    <Breadcrumbs
      color="whitesmoke"
      separator={<NavigateNextIcon fontSize="medium" />}
      aria-label="breadcrumb"
    >
      <StyledBreadcrumb
        label="Home"
        icon={<HomeIcon fontSize="small" />}
        onClick={() => handleClick('/dashboard')}
      />
      {pathnames.map((text, index) => {
        const last = index === pathnames.length - 1;
        const to = `/${pathnames.slice(0, index + 1).join('/')}`;
        
        return last ? (
          <StyledBreadcrumb
            key={to}
            label={text}
            sx={{
              cursor: 'default',
              pointerEvents: 'none',
            }}
          />
        ) : (
          <StyledBreadcrumb
            key={to}
            label={text}
            onClick={() => handleClick(to)}
          />
        );
      })}
    </Breadcrumbs>
  );
};

export default AppBreadcrumbs;
