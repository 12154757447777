import React, { useState } from "react";
import zIndex from "@mui/material/styles/zIndex";
import {
  Grid,
  Box,
  FormHelperText,
  Container,
  Paper,
  Typography,
  Button,
  IconButton,
  TextField,
  MenuItem,
  FormControl,
  InputLabel,
  InputAdornment,
} from "@mui/material";
import theme from "Theme";
import ISelect from "../../components/SelectField";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import ITimePicker from "../../components/ITimePicker";
import { getNames } from "country-list";
import countryTelephoneData from "country-telephone-data"; // Import the library
import dayjs from "dayjs";
import indianCities from "../../components/indianCities";
import { useCreateInterestedClientMutation } from "services/clientApi";
import { useLoading } from "../../components/LoadingProvider";
import { useSnackbar } from "../../components/SnackbarProvider";
import { useCreateInterestedCaregiverMutation } from "services/caregiverApi";
import { CaregiverStatuses } from "CommonData/Common";

const CaregiverApplyForm = () => {
  const showSnackbar = useSnackbar();
  const { startLoading, stopLoading } = useLoading();
  const [minEndTime, setMinEndTime] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);

  const countryList = Array.from(
    new Set(
      countryTelephoneData.allCountries.map((country) =>
        country.name.split("(")[0].trim()
      )
    )
  );
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  function getPhoneCode(countryName) {
    const country = countryTelephoneData.allCountries.find(
      (country) => country.name.split("(")[0].trim() == countryName
    );
    return country ? country.dialCode : "";
  }
  const getMinEndTime = (value) => {
    return value ? dayjs(value).add(30, "minute") : null;
  };
  const handleCountryTypeChange = (name, value) => {
    handleInputChange(name, value);
    handleInputChange("phoneCode", "+" + getPhoneCode(value) + "-");
  };
  const [countryType, setCountryType] = useState("");
  const [serviceType, setServiceType] = useState("");
  const [ic, setIc] = useState("");
  const [createInteretedCG] = useCreateInterestedCaregiverMutation();

  const [formValues, setFormValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    experienceLevel: "",
    qualityScore: "",
    contactNumber: "",
    preferredLocation: "",
    availableFrom: null,
    availableTo: null,
    comments: "",
    resumeFile: null,
  });

  const [formErrors, setFormErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    // experienceLevel: "",
    // qualityScore: "",
    contactNumber: "",
    preferredLocation: "",
    // availableFrom: "",
    // availableTo: "",
    resumeFile: "",
  });

  const handleInputChange = (name, value) => {
    setFormValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setFormErrors((prevState) => ({
      ...prevState,
      [name]: "",
    }));
  };
  const handleContactNumberChg = (name, value) => {
    const numericValue = value.replace(/[^0-9]/g, "");
  
    // Limit the input to 10 characters
    if (numericValue.length <= 10) {
      handleInputChange(name, numericValue);
    }
  };
  

  const handleAvailabilityChge = (name, value) => {
    handleInputChange(name, value);
    handleInputChange("availableTo", dayjs(value).add(30, "minute"));
    setMinEndTime(getMinEndTime(value));
  };

  const validateEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let errors = {
      firstName: !formValues.firstName.trim() ? "Full Name is required" : "",
      lastName: !formValues.lastName.trim() ? "Last Name is required" : "",
      // qualityScore: !formValues.qualityScore.trim()
      //   ? "Quality Score is required"
      //   : "",
      // experienceLevel: !formValues.experienceLevel.trim()
      //   ? "Experience Level is required"
      //   : "",
      resumeFile: !formValues.resumeFile ? "Resume is required" : "",
      email: !validateEmail(formValues.email) ? "Enter a valid email" : "",
      contactNumber: !formValues.contactNumber.trim()
      ? "Contact Number is required"
      : formValues.contactNumber.trim().length !== 10
      ? "Contact Number must be exactly 10 digits"
      : "",
      preferredLocation: !formValues.preferredLocation.trim()
        ? "Service Location is required"
        : "",
      // availableFrom: !formValues.availableFrom?.isValid()
      //   ? "Available Time is required"
      //   : "",
      // availableTo: !formValues.availableTo?.isValid()
      //   ? "Available Time is required"
      //   : "",
    };

    setFormErrors(errors);

    const isFormValid = Object.values(errors).every((error) => error === "");

    if (isFormValid) {
      try {
        startLoading();
        // Send POST request to backend
        const formData = new FormData();
        formData.append("firstName", formValues.firstName);
        formData.append("lastName", formValues.lastName);
        formData.append("contactNumber", "+91-" + formValues.contactNumber);
        formData.append("email", formValues.email);
        // formData.append("experienceLevel", formValues.experienceLevel);
        // formData.append("qualityScore", formValues.qualityScore);
        formData.append('resumeFile', formValues.resumeFile);
        formData.append("preferredLocation", formValues.preferredLocation);
        // formData.append(
        //   "availabilityStartTime",
        //   formValues.availableFrom.format("HH:mm")
        // );
        // formData.append(
        //   "availabilityEndTime",
        //   formValues.availableTo.format("HH:mm")
        // ); 
        
        formData.append("comments", formValues.comments);
        formData.append("status", CaregiverStatuses.INTERESTED.name);
        
        const response = await createInteretedCG(formData).unwrap();
        showSnackbar("Interest Sent for consideration", "success");
        // Handle response if needed
        //console.log('Response:', response);
      } catch (error) {
        showSnackbar(
          "Couldn't send your interest. Please retry after sometime",
          "error"
        );
        console.error("Error:", error);
      } finally {
        stopLoading();
        handleClose();
      }
    } else {
      // console.log("invalid form");
      // console.log(formErrors);
      // console.log(formValues);
    }
  };

  const handleServiceTypeChange = (event) => {
    setServiceType(event.target.value);
  };
  const shouldDisableEndTime = (timeValue) => {
    if (!formValues.availableFrom) {
      return false;
    }
    const minEndTime = dayjs(formValues.availableFrom).add(30, "minute");
    return dayjs(timeValue).isBefore(minEndTime);
  };

  const style = {
    backgroundColor: "white",
    color: theme.palette.primary.main,
    zIndex: 1,
  };

  const navigate = useNavigate();
  const handleClose = () => {
    navigate("/");
  };

  return (
    <Container
    
      maxWidth="md"
      // sx={{
      //   maxHeight: {
      //     xs: "100vh", // Adjust as needed
      //     sm: "none", // No max height for small and larger screens
      //   },
      //   overflowY: {
      //     xs: "auto", // Enable vertical scroll for extra-small screens
      //     sm: "visible", // Disable scroll for small and larger screens
      //   },
      // }}
    >
      <Box position="relative">
        <Paper style={style} elevation={3} sx={{ borderRadius:5, padding: 2 }}>
          <Typography variant="h5" gutterBottom>
            Caregiver Application
          </Typography>
          <IconButton
            onClick={handleClose}
            sx={{
              position: "absolute",
              top: "5px",
              right: "5px",
              color: "red",
              backgroundColor: "white",
              zIndex: 1,
            }}
          >
            <CloseIcon />
          </IconButton>
          <form onSubmit={handleSubmit}>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <TextField
                    label="First Name"
                    name="firstName"
                    value={formValues.firstName}
                    onChange={(e) =>
                      handleInputChange(e.target.name, e.target.value)
                    }
                    variant="outlined"
                    required
                  />
                  {formErrors.firstName && (
                    <FormHelperText sx={{ color: "red", height: "10px" }}>
                      {formErrors.firstName}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <TextField
                    label="Last Name"
                    name="lastName"
                    value={formValues.lastName}
                    onChange={(e) =>
                      handleInputChange(e.target.name, e.target.value)
                    }
                    variant="outlined"
                    required
                  />
                  {formErrors.lastName && (
                    <FormHelperText sx={{ color: "red", height: "10px" }}>
                      {formErrors.lastName}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth>
                  <TextField
                    label="Email"
                    name="email"
                    value={formValues.email}
                    onChange={(e) =>
                      handleInputChange(e.target.name, e.target.value)
                    }
                    variant="outlined"
                    required
                  />
                  {formErrors.email && (
                    <FormHelperText sx={{ color: "red", height: "10px" }}>
                      {formErrors.email}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4} className="custom-phone-input">
                <FormControl variant="outlined" fullWidth>
                  <TextField
                    InputProps={{
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          sx={{ color: "white" }}
                          color="primary"
                        >
                          <Box component="span" sx={{ color: "grey" }}>
                            +91 -
                          </Box>
                        </InputAdornment>
                      ),
                    }}
                    label="Contact Number"
                    name="contactNumber"
                    value={formValues.contactNumber}
                    onChange={(e) =>
                      handleContactNumberChg(e.target.name, e.target.value)
                    }
                    variant="outlined"
                    required
                  />
                  {formErrors.contactNumber && (
                    <FormHelperText sx={{ color: "red", height: "10px" }}>
                      {formErrors.contactNumber}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>

              {/* <Grid item xs={12} sm={4}>
                <FormControl fullWidth>
                  <TextField
                    label="Experience Level"
                    name="experienceLevel"
                    value={formValues.experienceLevel}
                    onChange={(e) =>
                      handleInputChange(e.target.name, e.target.value)
                    }
                    variant="outlined"
                    required
                  />
                  {formErrors.experienceLevel && (
                    <FormHelperText sx={{ color: "red", height: "10px" }}>
                      {formErrors.experienceLevel}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid> */}

              {/* <Grid item xs={12} sm={4}>
                <FormControl fullWidth>
                  <TextField
                    label="Quality Score"
                    name="qualityScore"
                    value={formValues.qualityScore}
                    onChange={(e) =>
                      handleInputChange(e.target.name, e.target.value)
                    }
                    variant="outlined"
                    required
                  />
                  {formErrors.qualityScore && (
                    <FormHelperText sx={{ color: "red", height: "10px" }}>
                      {formErrors.qualityScore}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid> */}
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth>
                  <ISelect
                    label="Preferred Location"
                    name="preferredLocation"
                    value={formValues.preferredLocation}
                    options={indianCities}
                    onChange={(e, newValue) =>
                      handleInputChange("preferredLocation", newValue)
                    }
                    variant="outlined"
                    required
                  />
                  {formErrors.preferredLocation && (
                    <FormHelperText sx={{ color: "red", height: "10px" }}>
                      {formErrors.preferredLocation}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Grid item xs={12} sm={4} fullWidth>
                  <ITimePicker
                    label="Availability (From)"
                    name="availableFrom"
                    value={formValues.availableFrom}
                    onChange={(newValue) =>
                      handleAvailabilityChge("availableFrom", newValue)
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={4} fullWidth>
                  <ITimePicker
                    label="Availability (To)"
                    name="availableTo"
                    value={formValues.availableTo}
                    minTime={minEndTime}
                    onChange={(newValue) =>
                      handleInputChange("availableTo", newValue)
                    }
                  />
                </Grid>
              </LocalizationProvider> */}
              <Grid item xs={12}>
              <FormControl fullWidth>
                <Button
                  variant="outlined"
                  component="label"
                >
                  Upload Resume
                  <input
                    type="file"
                    name='resumeFile'
                    hidden
                    accept=".pdf,.docx"
                    onChange={(e) => handleInputChange(e.target.name, e.target.files[0])}
                    />
                </Button>
                {formValues.resumeFile && <Typography variant="body2">{formValues.resumeFile?.name}</Typography>}
                {formErrors.resumeFile && 
                <FormHelperText sx={{ color: 'red', height: '10px' }}>{formErrors.resumeFile}</FormHelperText>
                }
              </FormControl>
            </Grid>

              <Grid item xs={12}>
                <TextField
                  label="Comments (If Any)"
                  multiline
                  rows={2}
                  onChange={(e) =>
                    handleInputChange("comments", e.target.value)
                  }
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid
                item
                sm={6}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                >
                  Apply
                </Button>
              </Grid>
              <Grid item sm={6}>
                <Button
                  variant="contained"                  
                  sx={{
                    bgcolor: "#FF0000",
                    '&:hover': {
                      bgcolor: "#CC0000", // Darker shade of red for hover effect
                    },
                  }}
                  onClick={handleClose}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Box>
    </Container>
  );
};

export default CaregiverApplyForm;
